<template>
    <!-- Start New Arrivals Product Area  -->
    <div>
        <div class="table-responsive" v-if="comparable.length > 0">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th></th>
                    <th>Este producto</th>
                    <th scope="col" v-for="product in comparable" :key="product">
                        <img data-sal-delay="200" data-sal-duration="800" loading="lazy" class="main-img"
                        :src="product.images[0].path" alt="Product Images"
                        style="width: 150px;height: 140px;object-fit: contain;">
                        <router-link :to="{ name: 'Show', params: { 'id': product.url_key } }">
                            <label for="" style="overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-align: justify;color: #1b5170;font-size: 12px;;width: 120px;">
                                {{ product.name }}
                            </label>
                        </router-link>
                        <label for="" style="font-size: 12px;" ><b>{{ $filters.money(product.best_price) }}</b></label><br>
                        <button type="button" class="btn btn-danger" @click.prevent="add(product.id)" style="font-size: 10px;width: 100%;">Añadir al carrito</button>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th><b>Opiniones</b></th>
                    <th class="text-center">
                        <vue3starRatings v-model="ratingProduct" class="disabled-rating text-center" :star-size="15" style="margin: auto;" />
                    </th>
                    <th v-for="rating in comparable" :key="rating" class="text-center">
                        <vue3starRatings v-model="rating.rating.rating" class="disabled-rating text-center" :star-size="15" style="margin: auto;" />
                    </th>
                </tr>
                <tr v-for="attributesGr in productAttributes" :key="attributesGr">
                    <th scope="row" class="text-center">
                        <b>{{ attributesGr.label }}</b> 
                    </th>
                    <td class="text-center">
                        <label for="">
                            {{ attributesGr.value }}
                        </label>
                    </td>
                    <td class="text-center" v-for="product in comparable" :key="product">
                        <div for="" v-for="attributes in product.category_attributes.attributes" :key="attributes">
                            <div v-if="attributes.label === attributesGr.label">
                                <label for="">{{ attributes.value }}</label>
                            </div>
                        </div>
                    </td>
                </tr>
                <!--tr>
                    <th scope="row" class="text-center"><b>Tamaño de la pantalla</b></th>
                    <td></td>
                    <td class="text-center" v-for="product in comparable" :key="product">
                        <div for="" v-for="attributes in product.category_attributes.attributes" :key="attributes">
                            <div v-if="attributes.label === 'Tamaño de la pantalla'">
                                <label for="">{{ attributes.value }}</label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th scope="row" class="text-center"><b>Sistema operativo</b></th>
                    <td></td>
                    <td class="text-center" v-for="product in comparable" :key="product">
                        <div for="" v-for="attributes in product.category_attributes.attributes" :key="attributes">
                            <div v-if="attributes.label === 'Sistema operativo'">
                                <label for="">{{ attributes.value }}</label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th scope="row" class="text-center"><b>Descripción del disco duro</b></th>
                    <td></td>
                    <td class="text-center" v-for="product in comparable" :key="product">
                        <div for="" v-for="attributes in product.category_attributes.attributes" :key="attributes">
                            <div v-if="attributes.label === 'Descripción del disco duro'">
                                <label for="">{{ attributes.value }}</label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th scope="row" class="text-center"><b>Marca del procesador</b></th>
                    <td></td>
                    <td class="text-center" v-for="product in comparable" :key="product">
                        <div for="" v-for="attributes in product.category_attributes.attributes" :key="attributes">
                            <div v-if="attributes.label === 'Marca del procesador'">
                                <label for="">{{ attributes.value }}</label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th scope="row" class="text-center"><b>Tamaño del disco duro</b></th>
                    <td></td>
                    <td class="text-center" v-for="product in comparable" :key="product">
                        <div for="" v-for="attributes in product.category_attributes.attributes" :key="attributes">
                            <div v-if="attributes.label === 'Tamaño del disco duro'">
                                <label for="">{{ attributes.value }}</label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th scope="row" class="text-center"><b>Marca de la tarjeta gráfica</b></th>
                    <td></td>
                    <td class="text-center" v-for="product in comparable" :key="product">
                        <div for="" v-for="attributes in product.category_attributes.attributes" :key="attributes">
                            <div v-if="attributes.label === 'Marca de la tarjeta gráfica'">
                                <label for="">{{ attributes.value }}</label>
                            </div>
                        </div>
                    </td>
                </tr-->
                </tbody>
            </table>
        </div>
    </div>
    <!-- End New Arrivals Product Area  -->
  </template>

<script>
//import { Carousel, Navigation, Slide } from 'vue3-carousel'
//import PlaceHolder from '../PlaceHolder/ReelProducts.vue'
import vue3starRatings from "vue3-star-ratings";
import store from '@/store'
import { useToast } from "vue-toastification";
import 'vue3-carousel/dist/carousel.css'
import { mapState } from 'vuex'
export default {
  name: 'ComparableProducts',
  components: {
    /*Carousel,
    Slide,
    Navigation,
    PlaceHolder*/
    vue3starRatings,
  },
  props: {
    msg: String,
    Reel: {
      required: true,
      type: Array
    },
  },
  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      breakpoints: {
        700: {
          itemsToShow: 3.5,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 5,
          snapAlign: 'start',
        },
      },
      toast: useToast(),
      wishlist: store.state.user.wishlist,
      productId: '',
      currentDate: this.formatDate(new Date()),
      comparable: [],
      productAttributes: [],
      ratingProduct:''
    }
  },
  methods: {
    add(id) {
        if (store.state.user.token) {
            store.dispatch("addProductToCart", {
                    quantity: 1,
                    product_id: id,
                    is_buy_now: 0,
                    inventory_id: 0
            })
            .then((res) => {
                    if(!res.data.success){
                        this.toast.error(res.data.errors[0])
                    }else{
                        this.toast.success('Articulo añadido al carrito')
                    }
                    store.dispatch("getCart")
                    
            }).catch((err) => {
                    console.log(err);
            });
        } else {
            let exists = false
            this.productsCartTemp.forEach((prod) => {
                    if (prod.productId == this.product.id) {
                        exists = true
                    }
            });

            if (this.quantity > this.product.inventory.qty && exists === false) {
                    this.productsTemp.push({ products: this.product, quantity: this.product.inventory.qty })
                    this.productsCartTemp.push({ productId: this.product.id, quantity: this.product.inventory.qty })
                    this.toast.success('Articulo añadido al carrito')
            }
            if (exists === false && this.quantity < this.product.inventory.qty) {
                    this.productsTemp.push({ products: this.product, quantity: parseInt(this.quantity) })
                    this.productsCartTemp.push({ productId: this.product.id, quantity: parseInt(this.quantity) })
                    this.toast.success('Articulo añadido al carrito')
            }
            if (exists === true) {
                    this.productsTemp.filter(product => {
                        if (product.products.id == this.product.id) {
                            this.total = product.quantity += parseInt(this.quantity)
                        }
                    })
                    this.updateProductsTemp();
                    this.toast.success('Articulo añadido al carrito')
            }
        }
    },
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    },
    comparableProducts(){
        store.dispatch("comparableProducts", {
            productId: this.productId,
        }).then((res) => {
            this.productAttributes = res.data.category_attributes.attributes;
            this.ratingProduct = res.data.rating.rating
        }).catch((err) => {
            console.log(err);
        });
    },
    relatedProducts(){
        store.dispatch('relatedProducts', this.productId)
        .then((res) => {
            this.comparable = res.data.payload.related.comparable.slice(this.comparable.length - 5);
            //.related.comparable.slice(this.comparable.length - 5);
        })
        .catch(error => {
            console.error('Error fetching getWishlist:', error);
        });
    },
  },
  mounted() {
    this.comparableProducts();
    this.relatedProducts();
  },
  computed: {
    ...mapState(['isLoading'])
  },
  created() {
    //this.comparable = this.Reel.related.comparable;
    this.productId = this.Reel
    },
}
</script>