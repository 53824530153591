import axiosClient from '@/axios'
import { createStore } from 'vuex'
import { API_ENDPOINTS } from '@/config/config';
//import { useToast } from 'vue-toastification'

//const toast = useToast()

export default createStore({
  state: {
    user: {
      data: {},
      cart: {},
      order: {},
      wishlist: {},
      tokenExpired: false,
      token: sessionStorage.getItem('TOKEN'),
      tokenFresh: sessionStorage.getItem('REFRESH_TOKEN'),
      user_id: ''
    },
    authenticated: false,
    name: '',
    email: '',
    date_of_birth: '',
    productsTemp: [],
    productsCartTemp: [],
    totalProductsCartTemp: [],
    isLoading: false,
    querySearch: [],
    searchedProduct: {},
    status: 404,
    recommendedProducts: []
  },
  getters: {
  },
  actions: {
    async userProfile({ commit }, user) {
      return await axiosClient.post(API_ENDPOINTS.userProfile, user)
        .then(({ data }) => {
          commit('setUser', data.user)
          commit('setName', data.user.first_name)
          commit('setDateOfBirth', data.user.date_of_birth)
          commit('setToken', data.requestToken)
          if(data.refreshToken){
            commit('setRefreshToken', data.refreshToken)
          }
          return data;
        }).catch(err => {
          return err.response
        })
    },
    async logout({ commit }) {
      return await axiosClient.post(API_ENDPOINTS.logout)
        .then(({ response }) => {
          commit('logout')
          return response;
        }).catch(err => {
          return err.response
        })
    },
    
    async refreshToken({ commit }, accessToken) {
        commit('setToken', accessToken)
    },
    // eslint-disable-next-line
    register({ commit }, form) {
      return axiosClient.post(API_ENDPOINTS.register, form)
        .then((result) => {
          console.log(commit)
          return result;
        }).catch((err) => {
          return err;
        });
    },
    /* verificationEmail({ commit }, email){
      return axiosClient.post(API_ENDPOINTS.verification, email)
      .then((result) => {
        console.log(commit)
        return result;
      }).catch((err) => {
        return err;
      });
    },*/
    async verificationEmail({ commit }, email) {
      return await axiosClient.post(API_ENDPOINTS.verification, email)
        .then(({ response }) => {
          commit('verification')
          return response;
        }).catch(err => {
          return err.response
        })
    },
    async getCategories() {
      return axiosClient.get(API_ENDPOINTS.getCategories)
        .then((res) => {
          return res;
        }).catch((err) => {
          return err
        });
    },
    async relatedProducts({ commit }, productId) {
      console.log(commit)
      return await axiosClient(API_ENDPOINTS.relatedProducts + productId)
        .then((res) => {
          return res
        }).catch((err) => {
          return err
        });
    },
    getUser({ commit }) {
      return axiosClient.get(API_ENDPOINTS.getUserData)
        .then((res) => {
          if (res.data) {
            commit('setUser', res.data.userDetails)
            commit('setName', res.data.userDetails.first_name)
            commit('setEmail', res.data.userDetails.email)
            commit('setOrder', res.data.userDetails.orders)
            commit('setUserId', res.data.userDetails.id)
          }
          return res;
        }).catch((err) => {
          console.log(err);
        });
    },
    async showProduct({ commit }, product_id) {
      console.log(commit)
      return await axiosClient(API_ENDPOINTS.getProduct + product_id)
        .then((res) => {
          commit('setStatus', res.request.status)
          return res
        }).catch((err) => {
          return err
        });
    },
    getCart({ commit }) {
      return axiosClient(API_ENDPOINTS.getCart)
        .then((res) => {
          commit('setCart', res.data.cart)
          return res;
        }).catch((err) => {
          return err;
        });
    },
    async productCategory({ commit }, slug) {
      console.log(commit)
      return await axiosClient(API_ENDPOINTS.getProductCategory + slug)
        .then((res) => {
          return res
        }).catch((err) => {
          return err
        });
    },
    addProductToCart({ commit }, data) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.addProductToCart, data)
        .then((res) => {
          console.log(res);
          return res;
        }).catch((err) => {
          console.log(err);
        });
    },
    createNewCart({ commit }, data) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.createNewCart, data)
        .then((res) => {
          console.log(res);
        }).catch((err) => {
          console.log(err);
        });
    },
    updateShoppingCart({ commit }, data) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.updateShoppingCart, data)
        .then((res) => {
          console.log(res);
        }).catch((err) => {
          console.log(err);
        });
    },
    async featuredProducts() {
      return axiosClient.get(API_ENDPOINTS.getFeaturedProducts)
        .then((res) => {
          return res;
        }).catch((err) => {
          return err
        });
    },
    async newProducts() {
      return axiosClient.get(API_ENDPOINTS.getNewProducts)
        .then((res) => {
          return res;
        }).catch((err) => {
          return err
        });
    },
    async removeItemFromCart({ commit }, item) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.removeItemFromCart, item)
        .then((res) => {
          return res
        }).catch((err) => {
          return err;
        });
    },
    async getAddresses() {
      return axiosClient.get(API_ENDPOINTS.getAddresses)
        .then((res) => {
          return res
        }).catch((err) => {
          return err;
        });
    },
    // eslint-disable-next-line
    applyCoupon({ commit }, coupon) {
      return axiosClient.post(API_ENDPOINTS.applyCoupon, coupon)
        .then((result) => {
          return result;
        }).catch((err) => {
          return err;
        });
    },
    // eslint-disable-next-line
    removeCoupon({ commit }, coupon) {
      return axiosClient.post(API_ENDPOINTS.removeCoupon, coupon)
        .then((result) => {
          return result;
        }).catch((err) => {
          return err;
        });
    },
    getShippingMethods() {
      return axiosClient.get(API_ENDPOINTS.getShippingMethods)
        .then((result) => {
          return result;
        }).catch((err) => {
          return err;
        });
    },
    applyShippingMethod({ commit }, shippingCode) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.applyShippingMethod, shippingCode)
        .then((res) => {
          return res
        }).catch((err) => {
          return err
        });
    },
    // eslint-disable-next-line
    saveAddress({ commit }, address) {
      return axiosClient.post(API_ENDPOINTS.saveAddress, address)
        .then((result) => {
          return result
        }).catch((err) => {
          return err;
        });
    },
    addProductToWishlist({ commit }, data) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.addProductToWishlist, data)
        .then((res) => {
         
          console.log(res);
        }).catch((err) => {
          console.log(err);
        });
    },
    getWishlist({ commit }) {
      return axiosClient(API_ENDPOINTS.getWishlist)
        .then((res) => {
          commit('setWishList', res.data.wishlist)
          return res;
        }).catch((err) => {
          return err;
        });
    },
    async removeProductFromWishlist({ commit }, item) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.removeProductFromWishlist, item)
        .then((res) => {
          return res
        }).catch((err) => {
          return err;
        });
    },
    createProductReview({ commit }, data) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.createProductReview, data)
        .then((res) => {
          console.log(res);
        }).catch((err) => {
          console.log(err);
        });
    },
    async deleteReview({ commit }, id) {
      console.log(commit)
      return await axiosClient.delete(API_ENDPOINTS.deleteReview + id)
        .then((res) => {
          return res
        }).catch((err) => {
          return err
        });
    },
    searchProduct({ commit }, search) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.searchProduct, search)
        .then((res) => {
          commit('setSearch', res.data.products)
          commit('setSearchedProduct', search)
          return res
        }).catch((err) => {
          console.log(err);
        });
    },
    processOrder({ commit },data) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.processOrder, data)
        .then((res) => {
          //commit('logout')
          console.log("url", res.data)
          window.location.replace(res.data)
        }).catch((err) => {
          console.log(err);
        });
    },
    validationSMS({ commit }, data) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.validationSMS, data)
        .then((res) => {
          // console.log(this.state.user.data.id)
          return res.data
        }).catch((err) => {
          console.log(err);
        });
    },
    validateCode({ commit }, data) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.validateCode, data)
        .then((res) => {
          // console.log(this.state.user.data.id)
          return res.data
        }).catch((err) => {
          console.log(err);
        });
    },
    async updateCustomer({ commit }, item) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.updateCustomer, item)
        .then((res) => {
          return res
        }).catch((err) => {
          return err;
        });
    },
    async getFaq(){
      return axiosClient.get(API_ENDPOINTS.faq)
      .then((res) => {
        return res
      }).catch((err) => {
        return err;
      });
    },
    async newCustomer({ commit }, data) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.newCustomer, data)
        .then((res) => {
          return res
        }).catch((err) => {
          return err;
        });
    },
    getTicketsReasons() {
      return axiosClient(API_ENDPOINTS.ticketsReasons)
        .then((res) => {
          return res;
        }).catch((err) => {
          return err;
        });
    },
    createTicket({ commit }, data) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.createTicket, data)
        .then((res) => {
          return res
        }).catch((err) => {
          console.log(err);
        });
    },
    getTickets() {
      return axiosClient(API_ENDPOINTS.getTickets)
        .then((res) => {
          return res;
        }).catch((err) => {
          return err;
        });
    },
    search({ commit }, data) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.search, data)
        .then((res) => {
          return res
        }).catch((err) => {
          console.log(err);
        });
    },
    getAverageRating({ commit }, data) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.getAverageRating, data)
        .then((res) => {
          return res
        }).catch((err) => {
          return err
        });
    },
    getPercentageRatings({ commit }, data) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.getPercentageRatings, data)
        .then((res) => {
          return res
        }).catch((err) => {
          return err
        });
    },
    comparableProducts({ commit }, data) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.comparableProducts, data)
        .then((res) => {
          return res
        }).catch((err) => {
          return err
        });
    },
    getOrders({ commit }) {
      console.log(commit)
      return axiosClient.post(API_ENDPOINTS.getOrders)
        .then((res) => {
          return res
        }).catch((err) => {
          return err
        });
    },
    /*cartTemp({commit}, data){
      commit('setSearchedProduct',data )
    }*/
  },
  mutations: {
    logout: (state) => {
      state.user.token = null;
      state.user.tokenFresh = null;
      state.user.data = {};
      state.cart = {};
      sessionStorage.removeItem("TOKEN");
      sessionStorage.removeItem("REFRESH_TOKEN");
    },
    setUser: (state, user) => {
      state.user.data = user;
    },
    setName:(state, name) =>{
      state.name = name
    },
    setDateOfBirth:(state, date_of_birth)=>{
      state.date_of_birth = date_of_birth
    },
    setEmail:(state, email) =>{
      state.email = email
    },
    // Request_token para cualquier con usuario autenticado
    setToken: (state, token) => {
      state.user.token = token;
      sessionStorage.setItem('TOKEN', token);
    },
    // Se solicita un nuevo token_refresh con este token
    setRefreshToken: (state, token) => {
      state.user.tokenFresh = token;
      sessionStorage.setItem('REFRESH_TOKEN', token);
    },
    setCart: (state, cart) => {
      state.user.cart = cart
    },
    setOrder: (state, orders) => {
      state.user.orders = orders
    },
    setUserId:(state, id) => {
      state.user.user_id = id
    },
    setWishList: (state, wishlist) => {
      state.user.wishlist = wishlist
    },
    increment(state) {
      state.count++
    },
    loading(state, isLoading) {
      if (isLoading) {
        state.isLoading = true
      } else {
        state.isLoading = false
      }
    },
    setSearch(state, querySearch) {
      state.querySearch = querySearch
    },
    setSearchedProduct(state, searchedProduct) {
      state.searchedProduct = searchedProduct
    },
    setStatus: (state, status) => {
      state.status = status
    },
    setProductsTemp(state, productsTemp) {
      state.productsTemp = productsTemp
    },
    setProductsCartTemp(state, productsCartTemp) {
      state.productsCartTemp = productsCartTemp
    },
    setTotalProductsCartTemp(state, totalProductsCartTemp) {
      state.totalProductsCartTempp = totalProductsCartTemp
    },
  },
  modules: {}
})
