// Función para obtener el historial desde localStorage o sessionStorage
export function getProductHistory() {
    // Obtiene el historial almacenado, si no hay historial, devuelve un array vacío
    const history = JSON.parse(localStorage.getItem('productHistory')) || [];
    return history;
  }
  
  // Función para agregar un producto al historial
  export function addProductToHistory(product) {
    // Obtén el historial actual
    const history = getProductHistory();
  
    // Verifica si el producto ya existe en el historial
    const productExists = history.some(item => item.id === product.id);
  
    if (!productExists) {
      // Si no existe, agrega el producto al principio del historial
      history.unshift(product);
    }
  
    // Limita el tamaño del historial (por ejemplo, 10 productos como máximo)
    if (history.length > 5) {
      history.pop();  // Elimina el último producto si hay más de 10
    }
  
    // Guarda el historial actualizado en localStorage
    localStorage.setItem('productHistory', JSON.stringify(history));
  }
  
  // Función para obtener el historial de productos
  export function getProductHistoryDisplay() {
    return getProductHistory();
  }
  