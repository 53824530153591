<template>
    <div class="row">
        <div class="col-lg-12 offset-xl-4" style="margin-top: 20%;">
            <div class="axil-signin-form-wrap">
                <div class="axil-signin-form">
                    <h3 class="title">Preguntas y respuestas</h3>
                    <div class="singin-form">
                        <div class="form-group">
                            <label>Buscar pregunta</label>
                            <input type="text" class="form-control" placeholder="Escribe para buscar pregunta" v-model="search" @keydown="searchFaq()">
                        </div>
                    </div>
                    <div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item fw-bold">Categorías</li>
                            <li class="list-group-item" v-for="faqCategory in faqsData" :key="faqCategory.id">
                                <button v-scroll-to="{ element: '#'+faqCategory.name.split(' ')[0], easing: 'linear', offset: -100 }" class="">{{ faqCategory.name }}</button>
                            </li>
                        </ul>
                    </div>
                    <div v-if="search.length < 1">
                        <div :id="faqCategory.name.split(' ')[0]" class="full-width" v-for="faqCategory in faqsData" :key="faqCategory.id"><!-- category -->
                            <h1 class="text-4xl font-bold mb-5">{{ faqCategory.name }}</h1>
                            <div class="faq-item mb-5" v-for="faq in faqCategory.frequent_questions" :key="faq.id">
                                <h2 class="text-center mt-5">
                                    {{ faq.question }}
                                </h2>
                                <p class="text-base">
                                    {{ faq.answer }}
                                </p>
                            </div>
                            <Divider/>
                        </div><!-- end category -->
                    </div>
                    <div class="w-full"  v-else><!-- right column -->
                        <div class="full-width"><!-- category -->
                            <h1 class="text-4xl fw-bold mb-5">Resultados de busqueda</h1>
                            <div v-if="resultsSearch.length > 0">
                                <div class="faq-item mb-5" v-for="res in resultsSearch" :key="res.id">
                                    <h2 class="text-large fw-bold mb-2">
                                        {{ res.question }}
                                    </h2>
                                    <p class="text-base">
                                        {{ res.answer }}
                                    </p>
                                </div>
                            </div>
                            <div v-else>
                                <h2 class="text-large font-bold mb-2">Sin resultados...</h2>
                            </div>
                            <Divider/>
                        </div><!-- end category -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
// Imports
import { onMounted, ref } from 'vue'
import store from '@/store'
const faqsData = ref([]);

const search = ref('');
const resultsSearch = ref([]);

const searchCollection = (nameKey, faqsArray = []) => {
    let results = []
    faqsArray = faqsData.value

    faqsArray.forEach((fq) => {
        fq.frequent_questions.forEach( (question) => {
            if (question.question.toLowerCase().includes(nameKey.toLowerCase())) {
                results.push(question)
            }
        })
    })
    return results
}

const searchFaq = () => {
    console.log(search.value.length)
    if(search.value.length > 1){
        resultsSearch.value = searchCollection(search.value)
    }else{
        resultsSearch.value = []
    }
}

onMounted( () => {
    store.dispatch('getFaq')
    .then((res) => {
        faqsData.value = res.data.faqs;
    }).catch((err) => {
        console.log(err);
    });
})
</script>

<style>
.axil-signin-form-wrap {
    margin: 80px 0 0 !important;
    height: calc(100vh - 200px);
    padding: 20px;
}
</style>