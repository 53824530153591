<template>
  <!-- Start New Arrivals Product Area  -->
  <div>
    <div>
      <div>
        <div v-if="isLoading">
          <PlaceHolder></PlaceHolder>
        </div>
        <div class="slick-single-layout" style="padding: 0 40px;">
          <div class="row row--15" style="width: 85%;margin: 0 auto;">
            <div class="col-xl-12 col-lg-12 col-sm-12 col-12 mb--30">
              <Carousel v-bind="settings" :breakpoints="breakpoints">
                <Slide v-for="(prod, index) in Reel" :key="index">
                  <div class="axil-product product-style-one border-product-slide">
                    <div class="thumbnail">
                      <router-link :to="{ name: 'Show', params: { 'id': prod.simple_fields.url_key } }">
                        <div v-for="(img, ix) in prod.images.slice(0, 1)" :key="ix">
                          <img data-sal-delay="200" data-sal-duration="800" loading="lazy" class="main-img" style="width: 200px;height: 200px;padding: 20px;object-fit: contain;"
                            :src="img.path" alt="Product Images">
                        </div>
                      </router-link>
                      <div class="label-block label-right" v-for="(product, ws) in wishlist" :key="ws">
                        <div class="product-badget" v-if="product.product_id === prod.id"
                          style="background: #ff0000;z-index: 100;position: relative;">
                          <a @click.prevent="productID(prod.id)">
                            <i class="far fa-heart"></i>
                          </a>
                        </div>
                        <div class="product-badget" v-else>
                          <a @click.prevent="addWishlist(prod.id)">
                            <i class="far fa-heart"></i>
                          </a>
                        </div>
                      </div>
                      <div class="label-block label-right" v-if="wishlist.length === 0">
                        <div class="product-badget">
                          <a @click.prevent="addWishlist(prod.id)">
                            <i class="far fa-heart"></i>
                          </a>
                        </div>
                      </div>
                      <div class="label-block label-right" v-if="prod.inventory.mb_special_price && prod.inventory.mb_special_price_to >= currentDate"
                        style="margin-top: 15%;">
                        <div class="product-badget" style="background: #38d10b;">OFERTA</div>
                      </div>
                      <div class="label-block label-left">
                        <div class="product-badget" v-if="prod.simple_fields.refurbished === 1" style="background: #000;">
                          REACONDICIONADO</div>
                      </div>
                    </div>
                    <div class="product-content">
                      <div class="inner">
                        <h5 class="title">
                          <router-link :to="{ name: 'Show', params: { 'id': prod.simple_fields.url_key } }">
                            <div
                              style="overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-align: center;color: #000;">
                              {{ prod.simple_fields.name }}
                            </div>
                          </router-link>
                        </h5>
                        <br>
                        <div class="product-price-variant">
                          <div v-if="prod.inventory.mb_special_price && prod.inventory.mb_special_price_to >= currentDate">
                            <span class="price current-price">
                              {{ $filters.money(prod.inventory.mb_special_price) }}
                            </span><br>
                            <span class="price old-price">
                              {{ $filters.money(prod.inventory.mb_price) }}
                            </span><br><br>
                          </div>
                          <div v-else style="margin-bottom: 37px;">
                            <span class="price current-price">
                              {{ $filters.money(prod.inventory.mb_price) }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slide>
                <template #addons>
                  <Navigation />
                </template>
              </Carousel>
              <div class="modal" :id="'modal' + product_id">
                <a href="#" class="modal-bg"></a>
                <div class="modal-content">
                  <a href="#" class="modal-exit"><i class="fas fa-times"></i></a>
                  <h3 class="modal-title axil-breadcrumb-item">¿Eliminar de la lista de deseos?</h3>
                  <div class="modal-text">
                    <div class="row">
                      <div class="col-6">
                        <b-button variant="light">
                          <a class="cancel-modal" href="#">Cancelar</a>
                        </b-button>
                      </div>
                      <div class="col-6">
                        <b-button variant="success" @click.prevent="removeItem(product_id)">Aceptar</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End New Arrivals Product Area  -->
</template>

<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import PlaceHolder from '../PlaceHolder/ReelProducts.vue'
import store from '@/store'
import { useToast } from "vue-toastification";
import 'vue3-carousel/dist/carousel.css'
import { mapState } from 'vuex'
export default {
  name: 'FeaturedProducts',
  components: {
    Carousel,
    Slide,
    Navigation,
    PlaceHolder
  },
  props: {
    msg: String,
    Reel: {
      required: true,
      type: Array
    },
  },
  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      breakpoints: {
        700: {
          itemsToShow: 3.5,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 5,
          snapAlign: 'start',
        },
      },
      toast: useToast(),
      wishlist: store.state.user.wishlist,
      product_id: 0,
      currentDate: this.formatDate(new Date())
    }
  },
  methods: {
    addWishlist(id_product) {
      if (store.state.user.token) {
        this.isLoading = true
        store.dispatch("addProductToWishlist", {
          productId: id_product
        }).then(() => {
              this.toast.success('Artículo añadido a favoritos')
        }).catch((err) => {
              console.log(err);
        }).finally(() => {
            this.getWishlist()
        });
      } else {
        this.toast.error('Inicia sesión para poder realizar esta acción')
      }
    },
    removeItem(id_product) {
      this.isLoading = true
      store.dispatch('removeProductFromWishlist', {
        productId: id_product,
      }).then(() => {
        this.toast.success("Artículo eliminado de la lista de deseos")
        window.location.href = '#'
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        store.dispatch("getWishlist")
        this.wishlist.pop()
      });
    },
    productID(productId) {
      this.product_id = productId
      return window.location.href = '#modal' + productId;
    },
    getWishlist() {  
      store.dispatch('getWishlist')
      .then((res) => {
          this.wishlist = res.data.wishlist
      })
      .catch(error => {
          console.error('Error fetching getWishlist:', error);
      });
    },
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }
  },
  computed: {
    ...mapState(['isLoading'])
  }
}
</script>